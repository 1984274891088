//Colors
// theme colors
$locum-red: #d82700 !default;
$locum-blue: #2568ea !default;
$locum-green: #8ac575 !default;
$locum-grey: #4a4a4a !default;
$locum-orange: #f7bd4e !default;
$locum-orange-new: #ffcc00 !default;

// main colors
$white: #fff !default;
$black: #000 !default;
$lighten-black: #444 !default;
$grey: #888 !default;
$lighten-grey: #c4c4c4 !default;
$red: #9f3a38 !default;

$error-background-color: #fff6f6 !default;
$error-border-color: #e0b4b4 !default;

$closed-branch-color: #c3c3c3 !default;

$is-draft-color: #62c0c0 !default;
$job-posted-color: #f6bd4e !default;
$input-border-color: #ccc !default;

$primaryBgColor: #f9f9f9;

$maxWidth: 1320px;
