@import '~reset-css';
@import '~react-notifications-component/dist/theme.css';
@import '~animate.css/animate.min.css';
@import '~semantic-ui-css/semantic.min.css';
@import '~react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';
@import '~rc-time-picker/assets/index.css';
@import '~react-big-calendar/lib/css/react-big-calendar.css';
@import './../../assets/scss/variables';

* {
    box-sizing: border-box;
}

html {
    background: $primaryBgColor;

    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    line-height: 1.5;
    color: #2a3e4a;
}

body {
    font-family: 'Open Sans', 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell',
        'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: relative;
    background: $primaryBgColor;
}

body,
html {
    height: auto;
    min-height: 100vh;
    min-width: 1440px;
    overflow: auto;
}

a {
    text-decoration: none;
}

.notification-container-bottom-right {
    min-width: 200px;
}

.main-container {
    margin: 0 auto;
    max-width: 1320px;
}

.main-wrapper {
    width: 100%;

    .back-button-holder {
        margin: 0 auto;
        width: 100%;
        max-width: 1320px;

        @media (max-width: 1445px) {
            padding: 0 60px;
            margin: 0;
        }
    }

    .content-holder {
        display: flex;
        justify-content: center;
        padding: 25px 60px 60px 60px;
    }

    aside {
        margin-right: 20px;
    }
}

// schedule calendar popup
.rbc-overlay {
    .rbc-overlay-header {
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        color: $lighten-black;
    }

    .rbc-event {
        background: $locum-green;
        border-radius: 2px;
        padding: 8px;

        .rbc-event-content {
            font-size: 14px;
            line-height: 17px;
            text-align: center;
        }
    }
}

.scrolling.dimmable > .dimmer {
    justify-content: center;
}

.calendar-page {
    height: 100%;
    position: relative;
    &__switcher {
        position: absolute;
        right: 0;
    }
}

.cursor-pointer {
    cursor: pointer;
}


// 
.table-default-logo {
    background-image: url('../../assets/icons/default-pharmacy.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-color: #eff3f9;
    width: 25px;
    height: 25px;
}