@import './../../../assets/scss/variables';
.global-loader {
    &.overlay {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(255, 255, 255, 0.75);
        z-index: 10;
        opacity: 0;
        pointer-events: none;
        transition: all 0.3s ease;

        &.isActive {
            opacity: 1;
            pointer-events: auto;
        }
    }

    &__loader {
        background-color: transparent !important;

        &.blue {
            .ui.loader {
                color: $black !important;

                &:before {
                    border-color: rgba(0, 0, 0, 0.15) !important;
                }

                &:after {
                    border-color: $locum-blue transparent transparent !important;
                }
            }
        }
    }
}
